import { DevBackend, FormatSimple, Tolgee } from '@tolgee/react';

export const tolgee = Tolgee()
  .use(DevBackend())
  .use(FormatSimple())
  .init({
    language: 'en',
    apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
  });
