import styles from './SVGBadge.module.scss';
import { IconProps } from './types';

export default function Icon({
  icon,
  theme = 'neutral',
  size = 's',
  mode = 'light',
  onClick,
}: IconProps) {
  const isButton = Boolean(onClick);

  function getThemeClass() {
    if (theme === 'secondary') {
      return styles.secondary;
    }
    if (theme === 'neutral') {
      if (mode === 'dark') {
        return styles.neutralDark;
      }
      return styles.neutral;
    }
    return styles.primary;
  }

  function getSizeClass() {
    switch (size) {
      case 'xs':
        return styles.xs;
      case 's':
        return styles.s;
      default:
        return styles.m;
    }
  }

  const themeClass = getThemeClass();
  const sizeClass = getSizeClass();

  return (
    <div
      className={cn(
        styles.iconContainer,
        themeClass,
        sizeClass,
        isButton && styles.buttonStyle,
      )}
      role={isButton ? 'button' : 'img'}
      onClick={onClick}
    >
      {icon}
    </div>
  );
}
