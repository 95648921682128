import { SideTabRef } from '@omnic/lk-ui';

export interface AddUserMenuProps {
  addUserRef: React.RefObject<SideTabRef>;
}

export enum InputName {
  EMAIL = 'email',
  PHONE = 'phone',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export type UserData = {
  [K in InputName]: string;
};
