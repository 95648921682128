/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SideTabRef } from '@omnic/lk-ui';
import type { PaginationState, SortingState } from '@tanstack/table-core';
import { useTranslate } from '@tolgee/react';
import { MRT_ColumnDef } from 'mantine-react-table';
import { Dots } from 'src/assets/icons';
import Button from 'src/components/Button';
import Table from 'src/components/Table';

import AddUserMenu from './components/AddUserMenu/AddUserMenu';
import UserCard from './components/UserCard';
import { useFilters } from './hooks/useFilters';
import { useUsersData } from './hooks/useUsersData';
import { User } from './types';
import styles from './Users.module.scss';

export default function Users() {
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const addUserRef = useRef<SideTabRef>(null);

  const navigate = useNavigate();

  const {
    filters: { selectors },
    removeFilter,
    resetFilters,
    selectFilters,
    selectedFilters,
  } = useFilters();

  const { data, isFetching, isLoading, isRefetching, refetch } = useUsersData({
    searchValue,
    sortingState,
    paginationState,
    selectedFilters,
  });

  const users = data?.data;
  const pagination = data?.extra;
  const { items_count: itemsCount = 0, num_pages: numPages = 0 } =
    pagination ?? {};

  const { t } = useTranslate();

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('users.name'),
        accessorFn: (row) => {
          const fullName = `${row.first_name || ''} ${row.last_name || ''}`;
          return fullName.trim();
        },
      },
      {
        accessorKey: 'email',
        header: t('users.email'),
      },
      {
        accessorKey: 'phone',
        header: t('users.phone'),
      },
      {
        accessorKey: 'status',
        header: t('users.status'),
        accessorFn: (row) => {
          const statusValue = row.status;
          const status = statusValue ? t(`users.status.${statusValue}`) : '-';

          return (
            <span className={cn(styles.status, styles[`status${statusValue}`])}>
              {status}
            </span>
          );
        },
      },
      {
        accessorKey: 'company',
        header: t('users.company'),
        accessorFn: (row) => row.company?.name,
      },
      {
        accessorKey: 'profile.name',
        header: t('users.profile'),
        accessorFn: (row) => {
          const profileValue = row.profile?.prototype;
          const isProfileExist = Number.isInteger(profileValue);
          const profile = isProfileExist
            ? t(`users.profile.${profileValue}`)
            : '-';
          return (
            <span className={isProfileExist ? styles.profile : undefined}>
              {profile}
            </span>
          );
        },
      },
      {
        accessorKey: 'viewMore',
        header: t('users.view_more'),
        enableSorting: false,
        Cell: ({ row }) => {
          const { uid } = row.original;
          return (
            <Dots
              className={styles.dots}
              onClick={() => {
                navigate({ search: `user_uid=${uid}` });
              }}
            />
          );
        },
      },
    ],
    [t, navigate],
  );

  return (
    <>
      <Table
        table={{
          data: users ?? [],
          columns,
          showSkeletons: isLoading,
          showLoadingOverlay: isFetching || isRefetching,
          rowCount: itemsCount,
          pageCount: numPages,
          renderEmptyRowsFallback: () => (
            <div className={styles.emptyMessage}>{t('users.empty')}</div>
          ),
        }}
        searching={{
          searchValue,
          onSearchChange: setSearchValue,
        }}
        pagination={{
          paginationState,
          onPaginationChange: setPaginationState,
        }}
        sorting={{
          sortingState,
          onSortingChange: setSortingState,
        }}
        selectedFilters={selectedFilters}
        selectFilters={selectFilters}
        removeFilter={removeFilter}
        resetFilters={resetFilters}
        filters={{ selectors }}
        extraComponent={
          <Button
            variant="primary"
            label={t('users.add_user')}
            onClick={() => addUserRef.current?.open()}
          />
        }
      />
      <UserCard refetchUsers={refetch} />
      <AddUserMenu addUserRef={addUserRef} />
    </>
  );
}
