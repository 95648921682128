/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';

import styles from './Button.module.scss';
import ButtonLoader from './components/ButtonLoader/ButtonLoader';
import type { ButtonProps } from './types';

export default function Button({
  disabled,
  isLoading = false,
  label,
  link,
  variant,
  ...props
}: ButtonProps): JSX.Element {
  const isDisabled = !!(isLoading || disabled);

  function getButton(): JSX.Element {
    function getVariant() {
      switch (variant) {
        case 'secondary':
          return styles.secondary;
        case 'outlined':
          return styles.outlined;
        case 'transparent':
          return styles.transparent;
        default:
          return styles.primary;
      }
    }

    const button = (
      <button
        disabled={isDisabled}
        className={cn(
          styles.button,
          getVariant(),
          isLoading && styles.loadingState,
        )}
        type="button"
        aria-label={label}
        {...props}
      >
        {label}
        <div className={styles.loaderWrapper}>
          <ButtonLoader buttonVariant={variant} />
        </div>
      </button>
    );

    if (link?.to) {
      return (
        <Link className={styles.link} to={isDisabled ? '' : link.to}>
          {button}
        </Link>
      );
    }

    if (link?.href) {
      return (
        <a
          className={styles.link}
          href={isDisabled ? '' : link.href}
          target={link.blank ? '_blank' : undefined}
          rel={link.blank ? 'noreferrer' : undefined}
        >
          {button}
        </a>
      );
    }

    return button;
  }

  return getButton();
}
