import { DatePicker } from '@mantine/dates';

import { DatesRange } from './types';

export default function DateSelector({
  datesRange,
  onChangeDatesRange,
}: DatesRange): JSX.Element {
  return (
    <DatePicker
      type="range"
      value={datesRange}
      onChange={onChangeDatesRange}
      styles={{
        monthLevel: {
          width: '100%',
          fontSize: '14px',
        },
        calendarHeader: {
          borderBottom: '1px solid #fc0',
          maxWidth: '100%',
          marginBottom: 0,
        },
        month: {
          width: '100%',
        },
        monthThead: {
          backgroundColor: '#f9f9f9',
        },
        weekday: {
          padding: '12px 0px',
          color: '#000',
        },
        day: {
          width: '100%',
          fontSize: '14px',
          borderRadius: 0,
          ':hover': {
            backgroundColor: 'transparent',
            border: '1px solid #fc0',
          },
          '&[data-in-range]': {
            color: '#000',
            backgroundColor: '#f2f2f2',
            ':hover': {
              backgroundColor: '#f2f2f2',
            },
          },
          '&[data-selected]': {
            color: '#000',
            backgroundColor: '#fc0',
            borderRadius: 0,
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
          '&[data-weekend]': {
            color: '#000',
          },
        },
        yearLevel: {
          width: '100%',
        },
        monthsList: {
          width: '100%',
        },
        pickerControl: {
          width: '100%',
          borderRadius: 0,
          ':hover': {
            backgroundColor: '#f2f2f2',
          },
        },
        decadeLevel: {
          width: '100%',
        },
        yearsList: {
          width: '100%',
        },
      }}
    />
  );
}
