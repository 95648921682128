import { type FC, useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { ChevronDownIcon } from 'src/assets/icons';

import { useLocalization } from './hooks/useLocalization';
import styles from './LocaleSelector.module.scss';
import type { LocaleSelectorProps } from './LocaleSelector.types';

export const LocaleSelector: FC<LocaleSelectorProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { availableLangs, lang, selectLang } = useLocalization();

  const { context, floatingStyles, refs } = useFloating({
    open: isOpen,
    placement: 'bottom-start',
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const handleSetLocale = (locale: string) => () => {
    selectLang(locale);
    setIsOpen(false);
  };

  return (
    <>
      <button
        ref={refs.setReference}
        type="button"
        className={cn(styles.root, className)}
        {...getReferenceProps()}
      >
        <span className={styles.title}>{lang?.toUpperCase()}</span>
        <ChevronDownIcon className={styles.icon} />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={styles.list}
            {...getFloatingProps()}
          >
            {availableLangs.map((locale) => (
              <div
                key={locale}
                role="presentation"
                className={styles.listItem}
                onClick={handleSetLocale(locale)}
              >
                {locale.toUpperCase()}
              </div>
            ))}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
