import { Warning } from '@omnic/lk-core';
import { useTranslate } from '@tolgee/react';
import { WarningFilled } from 'src/assets/icons';

import { Props } from './types';
import styles from './Warnings.module.scss';

export default function Warnings({ warnings, openCodeVerification }: Props) {
  const { t } = useTranslate();

  function onClickHandler(warning: Warning) {
    if (warning === Warning.NEED_CODE_VERIFICATION) {
      openCodeVerification();
    }
  }
  return (
    <div className={styles.main}>
      {warnings.map((warning) => {
        return (
          <div
            tabIndex={0}
            role="button"
            key={warning}
            className={styles.warning}
            onClick={() => onClickHandler(warning)}
          >
            <WarningFilled />
            {t(warning)}
          </div>
        );
      })}
    </div>
  );
}
