import ReactDOM from 'react-dom/client';
import { TolgeeProvider } from '@tolgee/react';

import { App } from './_app';
import { tolgee } from './i18n';

import './styles/index.scss';
import '@omnic/lk-ui/dist/style.css';

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     // See docs for support of different versions of variation of react router
//     // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
//     Sentry.reactRouterV6BrowserTracingIntegration({
//       useEffect: React.useEffect,
//       useLocation,
//       useNavigationType,
//       createRoutesFromChildren,
//       matchRoutes,
//     }),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       maskAllInputs: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // Session Replay
//   replaysSessionSampleRate: 1.0,
//   replaysOnErrorSampleRate: 1.0,
// });

ReactDOM.createRoot(document.getElementById('root')!).render(
  <TolgeeProvider tolgee={tolgee}>
    <App />
  </TolgeeProvider>,
);
