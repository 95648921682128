import { useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { Company, useCompaniesData } from 'src/api/useCompaniesData';
import { Profile, useProfilesData } from 'src/api/useProfilesData';
import type { DataForSelect } from 'src/components/Input/types';
import type { SelectedFilter } from 'src/components/Table/components/TableHeader/types';
import { useFiltersCreation } from 'src/components/Table/hooks/useFiltersCreation';

import { FilterIndex, Status, UserStatus } from '../types';

const userStatuses: Status[] = [
  {
    status: UserStatus.VERIFICATION_IS_NEEDED,
  },
  {
    status: UserStatus.VERIFICATION_REQUEST_SENT,
  },
  {
    status: UserStatus.ACTIVE,
  },
  {
    status: UserStatus.DEACTIVATED,
  },
];

export function useFilters() {
  const [filtersToApply, setFiltersToApply] = useState<SelectedFilter[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);

  const company = filtersToApply.find(
    (filter) => filter.filterIndex === FilterIndex.COMPANY,
  );
  const profile = filtersToApply.find(
    (filter) => filter.filterIndex === FilterIndex.PROFILE,
  );
  const status = filtersToApply.find(
    (filter) => filter.filterIndex === FilterIndex.STATUS,
  );

  const { t } = useTranslate();

  const {
    data: cData,
    isLoading: cIsFetching,
    refetch: cRefetch,
  } = useCompaniesData();

  const {
    data: pData,
    isLoading: pIsFetching,
    refetch: pRefetch,
  } = useProfilesData();

  const companies = cData?.data;
  const profiles = pData?.data;

  const companiesData = companies?.reduce<DataForSelect<Company>[]>(
    (acc, item) => {
      const value = item.name;
      acc.push({ value, data: item });
      return acc;
    },
    [],
  );

  const profilesData = profiles?.reduce<DataForSelect<Profile>[]>(
    (acc, item) => {
      const value = item.name;
      acc.push({ value, data: item });
      return acc;
    },
    [],
  );

  const statusesData = userStatuses.reduce<DataForSelect<Status>[]>(
    (acc, item) => {
      const value = t(`users.status.${item.status}`);
      acc.push({ value, data: item });
      return acc;
    },
    [],
  );

  function setFilters(filterIndex: FilterIndex, data: DataForSelect<object>) {
    const filterIdx = filtersToApply.findIndex(
      (filter) => filter.filterIndex === filterIndex,
    );

    const newFilter: SelectedFilter = { ...data, filterIndex };

    if (filterIdx !== -1) {
      const newFilters = [...filtersToApply];
      newFilters[filterIdx] = newFilter;
      setFiltersToApply(newFilters);
    } else {
      setFiltersToApply([...filtersToApply, newFilter]);
    }
  }

  function selectFilters() {
    setSelectedFilters(filtersToApply);
  }

  function resetFilters() {
    setFiltersToApply([]);
  }

  function removeFilter(filterIndex: number | 'all') {
    const newFilters =
      filterIndex !== 'all'
        ? selectedFilters.filter((filter) => filter.filterIndex !== filterIndex)
        : [];

    setFiltersToApply(newFilters);
    setSelectedFilters(newFilters);
  }

  // TODO: fix types
  const selectors = useFiltersCreation('selectors', [
    {
      filterName: t('filters.companies'),
      filterData: {
        isLoading: cIsFetching,
        value: company?.value,
        dataForSelect: companiesData,
        onDataSelect: (data) => {
          setFilters(FilterIndex.COMPANY, data as DataForSelect<Company>);
        },
        onOpenList: () => cRefetch(),
      },
    },
    {
      filterName: t('filters.profiles'),
      filterData: {
        isLoading: pIsFetching,
        value: profile?.value,
        dataForSelect: profilesData,
        onDataSelect: (data) => {
          setFilters(FilterIndex.PROFILE, data as DataForSelect<Profile>);
        },
        onOpenList: () => pRefetch(),
      },
    },
    {
      filterName: t('filters.status'),
      filterData: {
        value: status?.value,
        dataForSelect: statusesData,
        onDataSelect: (data) => {
          setFilters(FilterIndex.STATUS, data as DataForSelect<Status>);
        },
      },
    },
  ]);

  return {
    selectedFilters,
    filters: {
      selectors,
    },
    selectFilters,
    resetFilters,
    removeFilter,
  };
}
