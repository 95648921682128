import styles from './ButtonLoader.module.scss';
import type { ButtonLoaderProps } from './types';

export default function ButtonLoader({
  buttonVariant,
}: ButtonLoaderProps): JSX.Element {
  const loaderColor =
    buttonVariant === 'secondary' ? styles.secondary : styles.primary;

  return <div className={cn(styles.loader, loaderColor)} />;
}
