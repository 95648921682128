/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import { useMemo } from 'react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Icons,
  useMantineReactTable,
} from 'mantine-react-table';
import { Sorting, SortingDown, SortingUp } from 'src/assets/icons';

import TableHeader from './components/TableHeader';
import styles from './Table.module.scss';
import { AnyObject, TableProps } from './types';

export default function Table<TData extends AnyObject>({
  table: {
    columns,
    data,
    rowCount,
    pageCount,
    showSkeletons,
    showLoadingOverlay,
    renderEmptyRowsFallback,
  },
  sorting,
  pagination,
  ...tableHeaderProps
}: TableProps<TData>): JSX.Element {
  const sortingState = sorting?.sortingState;
  const paginationState = pagination?.paginationState;
  const onSortingChange = sorting?.onSortingChange;
  const onPaginationChange = pagination?.onPaginationChange;

  const icons: Partial<MRT_Icons> = useMemo(
    () => ({
      IconArrowsSort: () => <Sorting />,
      IconSortAscending: () => <SortingUp />,
      IconSortDescending: () => <SortingDown />,
    }),
    [],
  );

  const table = useMantineReactTable({
    columns: columns as MRT_ColumnDef<TData>[],
    data,
    onSortingChange,
    onPaginationChange,
    rowCount,
    pageCount,
    manualSorting: true,
    manualPagination: true,
    enableTopToolbar: false,
    enableRowSelection: false,
    enableColumnActions: false,
    enableFacetedValues: false,
    enableStickyHeader: true,
    paginationDisplayMode: 'pages',

    state: {
      sorting: sortingState,
      pagination: paginationState,
      showSkeletons,
      showLoadingOverlay,
    },

    renderEmptyRowsFallback,

    mantinePaginationProps: {
      withEdges: true,
      withControls: true,
    },

    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
      display: 'flex',
      sx: { flexGrow: 1, flexDirection: 'column' },
    },

    mantineTableContainerProps: {
      className: styles.tableContainer,
    },

    mantineTableHeadProps: {
      className: styles.tableHead,
    },

    mantineTableHeadCellProps: {
      className: styles.tableHeadCell,
    },

    mantineTableBodyCellProps: {
      className: styles.tableBodyCell,
    },

    mantineTableHeadRowProps: {
      sx: { boxShadow: 'none' },
    },

    mantineSelectCheckboxProps: {
      size: 'xs',
      className: styles.checkbox,
    },

    mantineSelectAllCheckboxProps: {
      size: 'xs',
      className: styles.checkbox,
    },

    mantineLoadingOverlayProps: {
      className: styles.loadingOverlay,
    },

    icons,
  });

  return (
    <div className={styles.root}>
      <TableHeader {...tableHeaderProps} />
      <MantineReactTable table={table} />
    </div>
  );
}
