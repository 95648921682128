import { useCallback, useLayoutEffect, useState } from 'react';
import { useUserContext } from 'src/context/userContext';
import { tolgee } from 'src/i18n';

const STORAGE_KEY = 'tolgee-lang-storage';

export function useLocalization() {
  const [lang, setLang] = useState<string>();
  const [availableLangs, setAvailableLangs] = useState<string[]>([]);

  const { user } = useUserContext();

  const setTranslatesHandler = useCallback((l: string) => {
    tolgee.changeLanguage(l);
    setLang(l);

    // const direction = l === "ar" || l === "he" || l === "iw" ? "rtl" : "ltr";

    document.documentElement.dir = 'ltr';
  }, []);

  const setAvailableLangsHandler = useCallback(
    (initialLang: string, langsFromProject?: string[]) => {
      if (!langsFromProject?.length) {
        return setAvailableLangs([initialLang]);
      }

      setAvailableLangs(langsFromProject);
    },
    [],
  );

  const selectLang = useCallback(
    (l: string) => {
      localStorage.setItem(STORAGE_KEY, l);
      setTranslatesHandler(l);
    },
    [setTranslatesHandler],
  );

  const initLangs = useCallback(async () => {
    const projectLangs = user?.organization?.pa_languages || [];

    const initialLang = projectLangs?.[0] || 'en';

    const savedLang = localStorage.getItem(STORAGE_KEY) as string | null;

    setTranslatesHandler(savedLang || initialLang);
    setAvailableLangsHandler(initialLang, projectLangs);
  }, [user, setTranslatesHandler, setAvailableLangsHandler]);

  useLayoutEffect(() => {
    initLangs();
  }, [initLangs]);

  return { lang, availableLangs, selectLang };
}
