/* eslint-disable no-promise-executor-return */

import { useRef, useState } from 'react';
import { useOutsideClick } from '@omnic/lk-core';
import { useTranslate } from '@tolgee/react';
import QRCode from 'qrcode.react';
import { Download, Share } from 'src/assets/icons';
import SVGBadge from 'src/components/SVGBadge';

import styles from './QRContainer.module.scss';
import { Props } from './types';

export default function QRContainer({ code }: Props): JSX.Element {
  const [isCropped, setIsCropped] = useState(false);

  const qrRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslate();

  const isShareSupported = navigator.share !== undefined;

  useOutsideClick({
    isOpen: isCropped,
    ref: qrRef,
    onOutsideClick: () => {
      setIsCropped(false);
    },
  });

  function downloadQR() {
    if (!document) return;

    const qrCodeURL = (document.getElementById('qrCodeEl') as HTMLCanvasElement)
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');

    const aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `QR_Code[${code}].png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  async function shareQR() {
    if (navigator.share) {
      try {
        const canvas = document.getElementById('qrCodeEl') as HTMLCanvasElement;
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        const file = new File([blob as Blob], 'qrcode.png', {
          type: 'image/png',
        });

        await navigator.share({
          files: [file],
          title: t('users.code_in_qr'),
          text: `${code}`,
        });
      } catch (error) {
        console.error('Error sharing QR code:', error);
      }
    } else {
      console.log('Web Share API is not supported in your browser.');
    }
  }

  function toggleCrop() {
    setIsCropped((prev) => !prev);
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{t('users.code_in_qr')}</span>
      <div className={styles.qrContainer}>
        <QRCode
          id="qrCodeEl"
          size={420}
          value={code}
          className={styles.qrForSharing}
        />
        <div className={styles.qrWrapper} ref={qrRef}>
          <QRCode
            size={isCropped ? 180 : 30}
            value={code}
            className={styles.qr}
            data-crop={isCropped}
            onClick={toggleCrop}
          />
        </div>
        <div className={styles.svgWrapper}>
          <SVGBadge icon={<Download />} onClick={downloadQR} />
          {isShareSupported && (
            <>
              <span className={styles.divider} />
              <SVGBadge icon={<Share />} onClick={shareQR} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
