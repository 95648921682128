import { paths } from '@omnic/lk-core';
import { useTranslate } from '@tolgee/react';
import {
  BillingIcon,
  CalculatorIcon,
  CouriersIcon,
  DashboardIcon,
  DeliveryOrdersIcon,
  DevicesIcon,
  OrdersIcon,
  PointsIcon,
  ReportsIcon,
  RequestsIcon,
} from 'src/assets/sidebar/icons';
import type { SidebarMenuItem } from 'src/types';

interface ReportMenuItemData {
  externalUrl: string;
}

function useBillingMenuItems(): SidebarMenuItem[] {
  const { t } = useTranslate();

  return [
    {
      id: 'cod',
      title: t('sidebar.billing.cod'),
      path: paths.billing.cod,
    },
    {
      id: 'bills',
      title: t('sidebar.billing.bills'),
      path: paths.billing.bills,
    },
    {
      id: 'agent-reports',
      title: t('sidebar.billing.agent_reports'),
      path: paths.billing.agent_reports,
      access: 'ec:api:ord:rep',
    },
  ];
}

function useReportsMenuItems(): SidebarMenuItem<ReportMenuItemData>[] {
  const { t } = useTranslate();

  return [
    {
      id: 'device-report',
      title: t('sidebar.reports.device'),
      path: `${paths.devices.reports.replace(':id', 'device')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/devices/content`,
      },
      access: 'cc:pc:rep:dev:old',
    },
    {
      id: 'device-report_next',
      title: t('sidebar.reports.device_next'),
      path: `${paths.devices.reports.replace(':id', 'device_next')}`,
      access: 'cc:pc:rep:dev:new',
    },
    {
      id: 'order-report',
      title: t('sidebar.reports.order'),
      path: `${paths.devices.reports.replace(':id', 'order')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/orders/content`,
      },
    },
    {
      id: 'summary-report',
      title: t('sidebar.reports.summary'),
      path: `${paths.devices.reports.replace(':id', 'summary')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/consolidated/content`,
      },
    },
    {
      id: 'cell-report',
      title: t('sidebar.reports.cell'),
      path: `${paths.devices.reports.replace(':id', 'cell')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/cells-lock/content`,
      },
    },
    {
      id: 'orders-in-the-device-have-been-processed',
      title: t('sidebar.reports.orders-processed'),
      path: `${paths.devices.reports.replace(':id', 'orders-processed')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/orders-processed/content`,
      },
    },
    {
      id: 'order-lifetime',
      title: t('sidebar.reports.order-lifetime'),
      path: `${paths.devices.reports.replace(':id', 'order-lifetime')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/orders-lifetime/content`,
      },
    },
    {
      id: 'devices-load-by-day',
      title: t('sidebar.reports.devices-load-by-day'),
      path: `${paths.devices.reports.replace(':id', 'devices-load-by-day')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/devices-congestion/content`,
      },
    },
    {
      id: 'overdue-orders',
      title: t('sidebar.reports.overdue-orders'),
      path: `${paths.devices.reports.replace(':id', 'overdue-orders')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/orders-expired/content`,
      },
    },
    {
      id: 'loading-devices-by-date',
      title: t('sidebar.reports.loading-devices-by-date'),
      path: `${paths.devices.reports.replace(':id', 'loading-devices-by-date')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/daily-ssp-workload/content`,
      },
    },
    {
      id: 'diagrams',
      title: t('sidebar.reports.diagrams'),
      path: `${paths.devices.reports.replace(':id', 'diagrams')}`,
      data: { externalUrl: `${import.meta.env.VITE_HUB_URL}/content` },
    },
    {
      id: 'feedback',
      title: t('sidebar.reports.feedback'),
      path: `${paths.devices.reports.replace(':id', 'feedback')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/feedback/content`,
      },
      access: ['pa:cc:rep:no', 'pa:cc:rep:lo'],
    },
    {
      id: 'devices-signal',
      title: t('sidebar.reports.devices-signal'),
      path: `${paths.devices.reports.replace(':id', 'devices-signal')}`,
      data: {
        externalUrl: `${import.meta.env.VITE_HUB_URL}/report/devices-signal/content`,
      },
      access: 'cc:pc:rep:sign',
    },
  ];
}

export function useSidebarMenuItems(): SidebarMenuItem<unknown, true>[] {
  const { t } = useTranslate();

  return [
    // --- devices microservice ---
    {
      id: 'dashboard',
      group: 'lockers',
      title: t('sidebar.dashboard'),
      path: paths.devices.dashboard,
      icon: DashboardIcon,
      access: 'cc:pc:dbrd',
    },
    {
      id: 'devices',
      group: 'lockers',
      title: t('sidebar.devices'),
      path: paths.devices.devices,
      icon: DevicesIcon,
      access: 'cc:pc:dev',
    },
    {
      id: 'orders',
      group: 'lockers',
      title: t('sidebar.orders'),
      path: paths.devices.orders,
      icon: OrdersIcon,
      access: 'cc:pc:ord:allowed',
    },
    {
      id: 'orders',
      group: 'lockers',
      title: `${t('sidebar.orders.new')}`,
      path: `${paths.devices.orders}_new`,
      icon: OrdersIcon,
      access: 'new:cc:pc:ord',
    },
    {
      id: 'orders',
      group: 'lockers',
      title: `${t('sidebar.orders.bff')}`,
      path: `${paths.devices.orders}_bff`,
      icon: OrdersIcon,
      access: 'neww:cc:pc:ord',
    },
    {
      id: 'reports',
      group: 'lockers',
      title: t('sidebar.reports'),
      path: paths.devices.reports,
      icon: ReportsIcon,
      children: useReportsMenuItems(),
      access: 'cc:pc:rep:allowed',
    },
    {
      id: 'requests',
      group: 'lockers',
      title: t('sidebar.requests'),
      path: paths.devices.requests,
      icon: RequestsIcon,
      data: { externalUrl: `${import.meta.env.VITE_HUB_URL}/requests/content` },
      access: 'cc:pc:req:allowed',
    },

    // --- delivery microservice ---
    {
      id: 'orders',
      group: 'delivery',
      title: t('sidebar.delivery.orders'),
      path: paths.delivery.orders,
      icon: DeliveryOrdersIcon,
      access: 'ec:api:ord:allowed',
    },
    {
      id: 'calculator',
      group: 'delivery',
      title: t('sidebar.delivery.calculator'),
      path: paths.delivery.calculator,
      icon: CalculatorIcon,
      access: 'tf:api:calc:allowed',
    },
    {
      id: 'couriers',
      group: 'delivery',
      title: t('sidebar.delivery.couriers'),
      path: paths.delivery.couriers,
      icon: CouriersIcon,
      access: 'ec:api:cc:allowed',
    },
    {
      id: 'points',
      group: 'delivery',
      title: t('sidebar.delivery.points'),
      path: paths.delivery.points,
      icon: PointsIcon,
      access: 'ec:api:pts',
    },

    // --- billing microservice ---
    {
      id: 'billing',
      group: 'others',
      title: t('sidebar.billing'),
      path: `${paths.billing.root}/:id`,
      icon: BillingIcon,
      access: 'de:billing:user',
      children: useBillingMenuItems(),
    },
  ];
}
