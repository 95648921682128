import type { Dispatch, Ref, RefObject, SetStateAction } from 'react';
import { useCallback, useImperativeHandle, useRef } from 'react';
import { useTranslate } from '@tolgee/react';

import type { InputError, InputRef } from '../types';

interface HookArgs {
  ref?: Ref<InputRef>;
  required: boolean;
  minPasswordLength: number;
  value?: string;
  setError: Dispatch<SetStateAction<InputError>>;
}

interface HookReturns {
  innerRef: RefObject<HTMLInputElement>;
  setInput: (value: string) => void;
}

export function useInputRef({
  minPasswordLength,
  ref,
  required,
  setError,
  value = '',
}: HookArgs): HookReturns {
  const innerRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslate();

  const setInput = useCallback((valueToSet: string) => {
    const input = innerRef.current;
    if (!input) return;

    const valueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    )?.set;

    valueSetter?.call(input, valueToSet);

    const event = new Event('input', { bubbles: true });
    input.dispatchEvent(event);
    input.focus();
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        setInput,
        setInputError: (errorText: string) => {
          setError({
            isError: true,
            errorMessage: errorText,
          });
        },
        isEmpty: () => {
          const isEmpty = !value.trim();

          if (required && isEmpty) {
            setError({
              isError: true,
              errorMessage: t('cannot_be_empty'),
            });
          }

          return required && isEmpty;
        },
        isPasswordValid: () => {
          // password requirements
          // - at least 8 characters
          // - at least 1 uppercase letter
          // - at least 1 lowercase letter
          // - at least 1 number

          const isPasswordValid =
            value.length >= minPasswordLength &&
            !!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/);

          if (!isPasswordValid) {
            setError({
              isError: true,
              errorMessage: t('password_invalid'),
            });
          }

          return isPasswordValid;
        },
        isEmailValid: () => {
          const isEmpty = !value.trim();
          const isEmailValid = /\S+@\S+\.\S{2,}/.test(value);

          if (isEmpty) {
            if (required) {
              setError({
                isError: true,
                errorMessage: t('cannot_be_empty'),
              });
            }

            return !required;
          }

          if (!isEmailValid && value.trim()) {
            setError({
              isError: true,
              errorMessage: t('email_is_not_valid'),
            });
          }

          return isEmailValid;
        },
        isPhoneValid: () => {
          const isEmpty = !value.trim();
          const isPhoneValid = /^\+?[0-9]{6,}$/.test(value);

          if (isEmpty) {
            if (required) {
              setError({
                isError: true,
                errorMessage: t('cannot_be_empty'),
              });
            }

            return !required;
          }

          if (!isPhoneValid && value.trim()) {
            setError({
              isError: true,
              errorMessage: t('phone_is_not_valid'),
            });
          }

          return isPhoneValid;
        },
      };
    },
    [required, value, minPasswordLength, t, setError, setInput],
  );

  return { innerRef, setInput };
}
