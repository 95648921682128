import { Filters } from '../components/TableHeader/components/FiltersMenu/types';

type FilterType = keyof Filters;

// TODO: fix types
export function useFiltersCreation<FT extends FilterType>(
  _: FT,
  filters: Filters[FT],
) {
  return filters;
}
