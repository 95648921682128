import { useRef } from 'react';
import { useOutsideClick } from '@omnic/lk-core';
import { useTranslate } from '@tolgee/react';
import { Cross } from 'src/assets/icons';
import Button from 'src/components/Button';
import { Input } from 'src/components/Input';

import styles from './FiltersMenu.module.scss';
import type { FiltersMenuProps } from './types';

export function FiltersMenu({
  filters,
  isOpenFiltersMenu,
  closeFiltersMenu,
  selectFilters,
  resetFilters,
}: FiltersMenuProps) {
  const ref = useRef<HTMLDivElement>(null);

  const stateClass = isOpenFiltersMenu ? styles.open : styles.closed;

  const { t } = useTranslate();

  const all = t('filters.all');

  useOutsideClick({
    ref,
    isOpen: isOpenFiltersMenu,
    onOutsideClick: closeFiltersMenu,
  });

  function onApplyFilters() {
    selectFilters?.();
    closeFiltersMenu();
  }

  const searchersList = filters?.searchers?.map((searcher) => {
    return (
      <Input
        variant="searching"
        key={searcher.filterName}
        label={searcher.filterName}
        searching={searcher.filterData as object}
        placeholder={all}
      />
    );
  });

  const selectorsList = filters?.selectors?.map((selector) => {
    return (
      <Input
        variant="selection"
        key={selector.filterName}
        label={selector.filterName}
        selection={selector.filterData as object}
        value={selector.filterData.value || all}
      />
    );
  });

  const multiSelectorsList = filters?.multiSelectors?.map((selector) => {
    return (
      <Input
        variant="selection"
        key={selector.filterName}
        label={selector.filterName}
        selection={selector.filterData as object}
        value={selector.filterData.value || all}
      />
    );
  });

  return (
    <div ref={ref} className={cn(styles.root, stateClass)}>
      <div className={styles.closeButtonContainer}>
        <button
          className={styles.closeButton}
          type="button"
          aria-label="close profile menu"
          onClick={closeFiltersMenu}
        >
          <Cross />
        </button>
      </div>
      <h2 className={styles.title}>{t('filter')}</h2>
      <form className={styles.form}>
        {searchersList}
        {selectorsList}
        {multiSelectorsList}
      </form>
      <div className={styles.buttonsWrapper}>
        <Button
          label={t('filters.reset')}
          variant="transparent"
          onClick={resetFilters}
        />
        <Button
          label={t('filters.filter')}
          variant="primary"
          onClick={onApplyFilters}
        />
      </div>
    </div>
  );
}
