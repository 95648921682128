/* eslint-disable jsx-a11y/no-static-element-interactions */

import type { ChangeEvent, ForwardedRef, MouseEvent } from 'react';
import {
  forwardRef,
  Fragment,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { useOutsideClick } from '@omnic/lk-core';
import {
  Calendar,
  Chevron,
  Copy,
  Cross,
  Download,
  EyeIcon,
  MGlass,
  Pen,
  Share,
} from 'src/assets/icons';

import SVGBadge from '../SVGBadge';
import DateSelector from './components/DateSelector';
import { DEFAULT_ERROR } from './constants';
import { useInputRef } from './hooks/useInputRef';
import styles from './Input.module.scss';
import type { DataForSelect, InputError, InputProps, InputRef } from './types';

// TODO: split into types of inputs

const InputComponent = <SR extends object, SL extends object>(
  {
    disabled,
    infoMessage,
    label,
    minPasswordLength = 8,
    onChange,
    onClick,
    required = false,
    searching,
    selection,
    datesRange,
    size = 'm',
    variant,
    type,
    value,
    extraActions,
    ...inputProps
  }: InputProps<SR, SL>,
  ref?: ForwardedRef<InputRef>,
): JSX.Element => {
  const id = useId();
  const [{ errorMessage, isError }, setError] =
    useState<InputError>(DEFAULT_ERROR);

  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [isOpenDateSelector, setIsOpenDateSelector] = useState(false);
  const [wasSelected, setWasSelected] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const inputWrapperRef = useRef<HTMLDivElement>(null);

  const sizeClass = size === 's' ? styles.s : styles.m;

  const selectionValue = selection?.value;
  const selectionDataForSelect = selection?.dataForSelect;
  const selectionIsLoading = selection?.isLoading;
  const selectionOnDataSelect = selection?.onDataSelect;
  const onOpenList = selection?.onOpenList;

  const searchingValue = searching?.value;
  const searchingDataForSelect = searching?.dataForSelect;
  const searchingIsLoading = searching?.isLoading;
  const searchingOnDataSelect = searching?.onDataSelect;
  const searchingOnSearchChange = searching?.onSearchChange;

  const datesRangeValue = datesRange?.value;

  const inputValue =
    value || selectionValue || searchingValue || datesRangeValue;
  const listData = selectionDataForSelect || searchingDataForSelect;
  const isDataInList = !!listData?.length;
  const isLoading = selectionIsLoading || searchingIsLoading;

  const isShowInputExtraInfo = !!infoMessage || (isError && !!errorMessage);
  const errorClass = isError ? styles.withError : '';
  const isDisabled =
    disabled ?? (variant === 'selection' || variant === 'date-range');

  useEffect(() => {
    if (isLoading) {
      setIsOpenSelect(true);
    }

    if (wasSelected) return;

    if (variant === 'searching') {
      if (inputValue) {
        setIsOpenSelect(true);
      } else {
        setIsOpenSelect(false);
      }
    }
  }, [isLoading, variant, inputValue, wasSelected]);

  function renderSelectionComponent() {
    if (isLoading) {
      return <div className={styles.loader} />;
    }

    if (Array.isArray(listData) && !isDataInList) {
      return <li>No data</li>;
    }

    if (isDataInList) {
      return listData?.map((item) => {
        return (
          <li
            key={item.value}
            className={styles.listItem}
            onClick={() => {
              if (variant === 'searching') {
                setWasSelected(true);
                searchingOnDataSelect?.(item as DataForSelect<SR>);
              }

              if (variant === 'selection') {
                selectionOnDataSelect?.(item as DataForSelect<SL>);
              }

              setIsOpenSelect(false);
            }}
          >
            {item.value}
          </li>
        );
      });
    }

    // Case when data is not loaded yet and user is typing
    if (variant === 'searching' && inputValue) {
      return <div className={styles.loader} />;
    }
  }

  useEffect(() => {
    setError(DEFAULT_ERROR);
  }, [inputValue]);

  useOutsideClick({
    ref: inputWrapperRef,
    isOpen: isOpenSelect || isOpenDateSelector,
    onOutsideClick: () => {
      setIsOpenSelect(false);
      setIsOpenDateSelector(false);
    },
  });

  const { innerRef, setInput } = useInputRef({
    ref,
    required,
    minPasswordLength,
    value: inputValue,
    setError,
  });

  function onInputClick(event: MouseEvent<HTMLInputElement>) {
    onClick?.(event);
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (variant === 'searching') {
      setWasSelected(false);
      searchingOnSearchChange?.(event.target.value);
    }

    if (variant === 'date-range') {
      datesRange?.onChange?.(event);
    }

    onChange?.(event);
  }

  function clearInput(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    setInput('');
  }

  function getMainActionIcon() {
    switch (variant) {
      case 'editing':
        return (
          <div className={styles.iconsGroup}>
            {!!inputValue && <SVGBadge icon={<Cross />} onClick={clearInput} />}
            <Pen />
          </div>
        );
      case 'searching':
        return (
          <div className={styles.iconsGroup}>
            {!!inputValue && <SVGBadge icon={<Cross />} onClick={clearInput} />}
            <MGlass />
          </div>
        );
      case 'selection':
        return <Chevron className={styles.chevron} />;
      case 'date-range':
        return (
          <div className={styles.iconsGroup}>
            {!!inputValue && <SVGBadge icon={<Cross />} onClick={clearInput} />}
            <Calendar />
          </div>
        );
      case 'password':
        return (
          <EyeIcon
            role="button"
            className={styles.eyeIcon}
            data-disabled={isPasswordVisible}
            onClick={() => setIsPasswordVisible((prev) => !prev)}
          />
        );
      default:
        return null;
    }
  }

  function renderIcons() {
    const mainActionIcon = !disabled && getMainActionIcon();
    const actionIcons = mainActionIcon ? [mainActionIcon] : [];

    extraActions?.forEach((action, idx) => {
      switch (action) {
        case 'copy':
          actionIcons.push(
            <SVGBadge
              key={`${idx + 1}-copy`}
              icon={<Copy />}
              onClick={() => {
                navigator.clipboard.writeText(inputValue || '');
              }}
            />,
          );
          break;
        case 'share':
          actionIcons.push(
            <SVGBadge
              key={`${idx + 1}-share`}
              icon={<Share />}
              onClick={() => {
                navigator.share({
                  title: 'Share',
                  text: inputValue || '',
                });
              }}
            />,
          );
          break;
        case 'download':
          actionIcons.push(
            <SVGBadge key={`${idx + 1}-download`} icon={<Download />} />,
          );
          break;
        default:
          break;
      }
    });

    const lastIndex = actionIcons.length - 1;

    return actionIcons.length ? (
      <div className={styles.iconsContainer}>
        {actionIcons.map((icon, index) => {
          const isLastElement = index === lastIndex;
          return (
            <Fragment key={`${index + 1}`}>
              {icon}
              {!isLastElement && <div className={styles.divider} />}
            </Fragment>
          );
        })}
      </div>
    ) : null;
  }

  function getType() {
    if (variant === 'password' && !isPasswordVisible) {
      return 'password';
    }
    return type;
  }

  function handleOnClick() {
    if (variant === 'date-range') {
      setIsOpenDateSelector((prev) => !prev);
    }
    if (variant === 'selection') {
      setIsOpenSelect((prev) => {
        if (!prev) {
          onOpenList?.();
        }
        return !prev;
      });
    }
  }

  useEffect(() => {
    if (datesRange?.value) {
      setIsOpenDateSelector(false);
    }
  }, [datesRange?.value]);

  return (
    <div
      key={id}
      className={cn(
        styles.inputContainer,
        errorClass,
        isOpenSelect && styles.openSelect,
      )}
    >
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label} {required && '*'}
        </label>
      )}
      <div ref={inputWrapperRef} className={styles.globalInputWrapper}>
        <div
          className={cn(
            styles.inputWrapper,
            sizeClass,
            disabled && styles.disabled,
          )}
          onClick={handleOnClick}
        >
          <input
            ref={innerRef}
            className={styles.input}
            disabled={isDisabled}
            id={id}
            value={inputValue}
            required={required}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            onClick={onInputClick}
            onChange={onInputChange}
            type={getType()}
            {...inputProps}
          />
          {renderIcons()}
        </div>
        <div className={cn(styles.extraContainer, styles.listContainer)}>
          <ul className={styles.list}>{renderSelectionComponent()}</ul>
        </div>
        {isOpenDateSelector && (
          <div className={styles.extraContainer}>
            <DateSelector
              datesRange={datesRange?.datesRange}
              onChangeDatesRange={datesRange?.onChangeDatesRange}
            />
          </div>
        )}
      </div>
      {isShowInputExtraInfo && (
        <span className={styles.infoText}>{errorMessage || infoMessage}</span>
      )}
    </div>
  );
};

const Input = forwardRef(InputComponent);

Input.displayName = 'Input';
export default Input;
