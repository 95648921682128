import { type FC, useState } from 'react';
import { SideTab } from '@omnic/lk-ui';
import { Chevron } from 'src/assets/icons';

import PasswordChange from './components/PasswordChange';
import UserInfo from './components/UserInfo';
import styles from './ProfileMenu.module.scss';
import type { ProfileMenuProps, View } from './types';

export const ProfileMenu: FC<ProfileMenuProps> = ({ sideTabRef }) => {
  const [view, setView] = useState<View>('user-info');

  function selectUserInfo() {
    setTimeout(() => {
      setView('user-info');
    }, 0);
  }

  function selectPasswordChange() {
    setTimeout(() => {
      setView('password-change');
    }, 0);
  }

  function getView() {
    switch (view) {
      case 'password-change':
        return <PasswordChange />;
      default:
        return <UserInfo onUpdate={selectPasswordChange} />;
    }
  }

  return (
    <SideTab
      onClose={selectUserInfo}
      ref={sideTabRef}
      extraIcon={
        view === 'password-change' ? (
          <div className={styles.chevronWrapper}>
            <Chevron />
          </div>
        ) : undefined
      }
      onExtraIconClick={selectUserInfo}
    >
      <div className={styles.main}>{getView()}</div>
    </SideTab>
  );
};
