import { type FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthProvider as OmnicAuthProvider } from '@omnic/lk-ui';
import { match, P } from 'ts-pattern';

enum Hostname {
  CHIP = 'chip',
  ARAMEX = 'aramex',
  OMNILLAMA = 'omnillama',
  CENTRO = 'centro',
}

export const AuthProvider: FC = () => {
  const [params] = useSearchParams();

  const { hostname } = window.location;
  const originUrl = window.location.origin;

  const isUseEnvForQuery = !!hostname.match(/^my\./);
  const logoName = hostname.split('.')[0];
  const redirectUrl = isUseEnvForQuery
    ? import.meta.env.VITE_OIDC_REDIRECT_URL
    : `${originUrl}/`;

  const queryParams = [...params.entries()]
    // whitelist params to prevent doubled OIDC state
    .filter(([key]) => ['ct', 'vt', 'logo'].includes(key))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Dictionary<string>);

  queryParams.logo = logoName;

  const authority = match(queryParams)
    .with(
      { ct: P.string },
      () => `${import.meta.env.VITE_OIDC_AUTHORITY}invite/`,
    )
    .with(
      { vt: P.string },
      () => `${import.meta.env.VITE_OIDC_AUTHORITY}registration/`,
    )
    .otherwise(() => import.meta.env.VITE_OIDC_AUTHORITY);

  function getClientId() {
    if (hostname.includes(Hostname.CHIP)) {
      return import.meta.env.VITE_OIDC_CLIENT_ID_CHIP;
    }
    if (hostname.includes(Hostname.ARAMEX)) {
      return import.meta.env.VITE_OIDC_CLIENT_ID_ARAMEX;
    }
    if (hostname.includes(Hostname.OMNILLAMA)) {
      return import.meta.env.VITE_OIDC_CLIENT_ID_OMNILLAMA;
    }
    if (hostname.includes(Hostname.CENTRO)) {
      return import.meta.env.VITE_OIDC_CLIENT_ID_CENTRO;
    }
    return import.meta.env.VITE_OIDC_CLIENT_ID_DEFAULT;
  }

  return (
    <OmnicAuthProvider
      authority={authority}
      extraQueryParams={queryParams}
      clientId={getClientId()}
      redirectUri={redirectUrl}
    />
  );
};
