import type { FC } from 'react';
import type { UIMatch } from 'react-router-dom';
import { useMatches } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';
import invariant from 'invariant';

import styles from './Breadcrumbs.module.scss';
import type { BreadcrumbsProps, Crumb } from './Breadcrumbs.types';

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ className }) => {
  const { t } = useTranslate();

  const matches = useMatches() as UIMatch<unknown, RouteHandle>[];

  const crumbs: Crumb[] = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => {
      invariant(match.handle.crumb, 'crumb is required');

      return {
        pathname: match.pathname,
        crumb: match.handle.crumb(t, match, styles),
      };
    });

  return (
    <ol className={cn(styles.root, className)}>
      {crumbs
        .reduce(
          (list, crumb, index: number) => {
            const separator: Crumb = {
              pathname: null,
              crumb: '/',
            };

            if (index === 0) {
              return [];
            }

            if (index === 1) {
              return [...list, crumb];
            }

            return [...list, separator, crumb];
          },
          [] as typeof crumbs,
        )
        .map((crumb, index) => (
          <li key={++index}>{crumb.crumb}</li>
        ))}
    </ol>
  );
};
