import { type FC, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Warning } from '@omnic/lk-core';
import { SideTabRef, Spinner } from '@omnic/lk-ui';
import { useAuth } from 'oidc-react';
import { LogoIcon } from 'src/assets/icons';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { LocaleSelector } from 'src/components/LocaleSelector';
import { ProfileButton } from 'src/components/ProfileButton';
import { useUserContext } from 'src/context/userContext';

import { CodeVerification } from './components/CodeVerification/CodeVerification';
import { ProfileMenu } from './components/ProfileMenu';
import Warnings from './components/Warnings/Warnings';
import styles from './Header.module.scss';
import type { HeaderProps } from './Header.types';

export const Header: FC<HeaderProps> = ({ className }) => {
  const { userManager } = useAuth();
  const { isUserLoaded, isUserLoading, user } = useUserContext();

  const warnings = user?.pa_warnings?.filter(
    (warning) => warning !== Warning.NEED_ADDRESS_VERIFICATION,
  );
  const isShowWarnings = !!warnings?.length;

  const profileMenuRef = useRef<SideTabRef>(null);
  const codeVerificationRef = useRef<SideTabRef>(null);

  const organization = user?.organization;
  const hasOrganizationLogo = !!organization?.logo;

  function openProfileMenu() {
    profileMenuRef.current?.open();
  }

  function openCodeVerification() {
    codeVerificationRef.current?.open();
  }

  useEffect(() => {
    if (!organization?.logout) return;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(organization.logout)) {
      userManager.settings.extraQueryParams[key] = value;
    }
  }, [organization?.logout, userManager.settings]);

  return (
    <>
      <nav className={cn(styles.root, className)}>
        <Link className={styles.logo} to="/">
          {isUserLoading && !isUserLoaded && (
            <Spinner className={styles.spinner} />
          )}
          {isUserLoaded && hasOrganizationLogo && (
            <img
              src={organization.logo}
              alt={organization.name}
              className={styles.logoImage}
            />
          )}
          {isUserLoaded && !hasOrganizationLogo && <LogoIcon />}
        </Link>

        <div className={styles.content}>
          <Breadcrumbs className={styles.breadcrumbs} />

          <div className={styles.actions}>
            {isShowWarnings && (
              <Warnings
                warnings={warnings}
                openCodeVerification={openCodeVerification}
              />
            )}
            <LocaleSelector />
            {/* <EComButton /> */}
            <ProfileButton openProfileMenu={openProfileMenu} />
          </div>
        </div>
      </nav>
      <ProfileMenu sideTabRef={profileMenuRef} />
      <CodeVerification sideTabRef={codeVerificationRef} />
    </>
  );
};
