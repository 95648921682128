import { useRef, useState } from 'react';
import { SideTab } from '@omnic/lk-ui';
import { useTranslate } from '@tolgee/react';
import { Company, useCompaniesData } from 'src/api/useCompaniesData';
import { Profile, useProfilesData } from 'src/api/useProfilesData';
import Button from 'src/components/Button';
import { Input } from 'src/components/Input';
import { DataForSelect, InputRef } from 'src/components/Input/types';

import styles from './AddUserMenu.module.scss';
import { DEFAULT_USER_DATA } from './constants';
import { AddUserMenuProps, InputName, UserData } from './types';
import { useAddUser } from './utils/addUser';

export default function AddUserMenu({
  addUserRef,
}: AddUserMenuProps): JSX.Element {
  const [selectedProfile, setSelectedProfile] = useState<Profile>();
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [userData, setUserData] = useState<UserData>(DEFAULT_USER_DATA);

  const profileRef = useRef<InputRef>(null);
  const companyRef = useRef<InputRef>(null);
  const emailRef = useRef<InputRef>(null);
  const phoneRef = useRef<InputRef>(null);

  const { t } = useTranslate();

  const { isPending, mutate } = useAddUser();

  const {
    data: cData,
    isLoading: cIsFetching,
    refetch: cRefetch,
  } = useCompaniesData();

  const {
    data: pData,
    isLoading: pIsFetching,
    refetch: pRefetch,
  } = useProfilesData();

  const companies = cData?.data;
  const profiles = pData?.data;

  const companiesData = companies?.reduce<DataForSelect<Company>[]>?.(
    (acc, item) => {
      const value = item.name;
      acc.push({ value, data: item });
      return acc;
    },
    [],
  );

  const profilesData = profiles?.reduce<DataForSelect<Profile>[]>?.(
    (acc, item) => {
      const value = item.name;
      acc.push({ value, data: item });
      return acc;
    },
    [],
  );

  function setValue(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const trimmedValue = value.trim();

    setUserData((prev) => ({ ...prev, [name]: trimmedValue }));
  }

  function onConfirm() {
    let isError = false;
    if (!selectedProfile) {
      profileRef.current?.setInputError(t('users.profile_required'));
      isError = true;
    }
    if (!selectedCompany) {
      companyRef.current?.setInputError(t('users.company_required'));
      isError = true;
    }

    const isEmailValid = emailRef.current?.isEmailValid();
    const isPhoneValid = phoneRef.current?.isPhoneValid();

    if (isError || !isEmailValid || !isPhoneValid) return;

    const { prototype } = selectedProfile!;
    const contractUid = selectedCompany!.contract.uid;
    const isPhoneNumberExist = userData.phone;
    const phoneNumber = userData.phone.includes('+')
      ? userData.phone
      : `+${userData.phone}`;

    mutate(
      {
        prototype,
        contract_uid: contractUid,
        email: userData.email,
        first_name: userData.firstName || undefined,
        last_name: userData.lastName || undefined,
        phone: isPhoneNumberExist ? phoneNumber : undefined,
        redirect_url: window.location.origin,
      },
      {
        onSuccess: () => setIsShowSuccess(true),
        onError: (error) => {
          if ((error as any).response.data.ids === 'validation_error') {
            phoneRef.current?.setInputError(t('phone_is_not_valid'));
          }
        },
      },
    );
  }

  function clearValues() {
    setSelectedProfile(undefined);
    setSelectedCompany(undefined);
    setUserData(DEFAULT_USER_DATA);
    setIsShowSuccess(false);
  }

  return (
    <SideTab ref={addUserRef} onClose={clearValues}>
      <div className={styles.main}>
        <h1 className={styles.title}>{t('users.add_user_to_company')}</h1>
        <div className={styles.content}>
          <Input
            ref={profileRef}
            label={t('users.profile')}
            variant="selection"
            value={selectedProfile?.name || t('choose_from_list')}
            selection={{
              dataForSelect: profilesData,
              isLoading: pIsFetching,
              onOpenList: pRefetch,
              onDataSelect: (data) => {
                setSelectedProfile(data.data as Profile);
              },
            }}
          />
          <Input
            ref={companyRef}
            label={t('users.company')}
            variant="selection"
            value={selectedCompany?.name || t('choose_from_list')}
            selection={{
              dataForSelect: companiesData,
              isLoading: cIsFetching,
              onOpenList: cRefetch,
              onDataSelect: (data) => {
                setSelectedCompany(data.data as Company);
              },
            }}
          />
          <Input
            required
            ref={emailRef}
            name={InputName.EMAIL}
            label={t('users.email')}
            variant="editing"
            value={userData.email}
            placeholder="-"
            onChange={setValue}
          />
          <Input
            ref={phoneRef}
            name={InputName.PHONE}
            label={t('users.phone_optional')}
            variant="editing"
            value={userData.phone}
            placeholder="-"
            onChange={setValue}
          />
          <Input
            name={InputName.FIRST_NAME}
            label={t('users.first_name_optional')}
            variant="editing"
            value={userData.firstName}
            placeholder="-"
            onChange={setValue}
          />
          <Input
            name={InputName.LAST_NAME}
            label={t('users.last_name_optional')}
            variant="editing"
            value={userData.lastName}
            placeholder="-"
            onChange={setValue}
          />
          <Button
            isLoading={isPending}
            label={t('user.confirm')}
            variant="primary"
            onClick={onConfirm}
          />
          {isShowSuccess && (
            <h2 className={styles.titleSuccess}>
              {t('invite_sent_successfully')}
            </h2>
          )}
        </div>
      </div>
      <div className={styles.info}>
        <ol className={styles.olList}>
          <li>{t('users.add_user_info_1')}</li>
          <li>{t('users.add_user_info_2')}</li>
        </ol>
      </div>
    </SideTab>
  );
}
