import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useScopeAccess } from '@omnic/lk-core';
import { SideTab, SideTabRef, SpinnerOverlay } from '@omnic/lk-ui';
import { useTranslate } from '@tolgee/react';
import Button from 'src/components/Button';
import { Input } from 'src/components/Input';
import { InputRef } from 'src/components/Input/types';

import { UserStatus } from '../../types';
import ChangeStatus from './components/ChangeStatus';
import QRContainer from './components/QRContainer';
import StatusContainer from './components/StatusContainer';
import { DEFAULT_PERSONAL_DATA } from './constants';
import { useUserData } from './hooks/useUsersData';
import { InputName, PersonalData, Props, UserCardFlow } from './types';
import styles from './UserCard.module.scss';
import { useUpdateMember } from './utils/updateMember';

export default function UserCard({ refetchUsers }: Props): JSX.Element {
  const [userCardFlow, setUserCardFlow] = useState<UserCardFlow>('default');
  const [searchParams, setSearchParams] = useSearchParams();
  const [personalData, setPersonalData] = useState<PersonalData>(
    DEFAULT_PERSONAL_DATA,
  );

  const userUid = searchParams.get('user_uid') ?? undefined;
  const hasAccess = useScopeAccess('id:pc:admin');

  const sideTabRef = useRef<SideTabRef>(null);
  const phoneRef = useRef<InputRef>(null);
  const emailRef = useRef<InputRef>(null);

  const { data, isLoading, refetch } = useUserData(userUid);
  const user = data?.data[0];

  const { isPending, mutate } = useUpdateMember();

  const { t } = useTranslate();

  const normalizeToString = useCallback(
    (value?: string | null) => value || '',
    [],
  );

  const isActiveSaveChanges =
    personalData.email &&
    personalData.phone &&
    personalData.firstName &&
    (personalData.email !== normalizeToString(user?.email) ||
      personalData.phone !== normalizeToString(user?.phone) ||
      personalData.firstName !== normalizeToString(user?.first_name) ||
      personalData.lastName !== normalizeToString(user?.last_name));

  useEffect(() => {
    if (userUid) {
      sideTabRef.current?.open();
    }
  }, [userUid]);

  useEffect(() => {
    if (!user) return;

    setPersonalData({
      email: normalizeToString(user.email),
      firstName: normalizeToString(user.first_name),
      lastName: normalizeToString(user.last_name),
      phone: normalizeToString(user.phone),
    });
  }, [user, normalizeToString]);

  function removeUserUid() {
    searchParams.delete('user_uid');
    setSearchParams(searchParams);
  }

  function toDefaultFlow() {
    setUserCardFlow('default');
  }

  function toChangeStatusFlow() {
    setUserCardFlow('change-status');
  }

  function onSideTabClose() {
    removeUserUid();
    toDefaultFlow();
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setPersonalData((prev) => ({ ...prev, [name]: value }));
  }

  function onSaveChanges() {
    const isValidEmail = emailRef.current?.isEmailValid();
    const isValidPhone = phoneRef.current?.isPhoneValid();

    if (isValidEmail && isValidPhone) {
      mutate(
        {
          uid: userUid!,
          email: personalData.email,
          first_name: personalData.firstName,
          last_name: personalData.lastName,
          phone: personalData.phone,
        },
        {
          onSuccess: () => {
            Promise.all([refetchUsers(), refetch()]);
          },
          onError: (error) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const axiosErrorData = (error as any).response?.data;

            console.error(axiosErrorData);

            if (axiosErrorData?.ids === 'validation_error') {
              if (axiosErrorData?.data?.[0]?.loc?.includes('email')) {
                emailRef.current?.setInputError(t('email_is_not_valid'));
              }
              if (axiosErrorData?.data?.[0]?.loc?.includes('phone')) {
                phoneRef.current?.setInputError(t('phone_is_not_valid'));
              }
            }

            if (axiosErrorData?.ids === 'already_exist') {
              if (axiosErrorData?.message?.includes('email')) {
                emailRef.current?.setInputError(t('email_already_exists'));
              }
              if (axiosErrorData?.message?.includes('phone')) {
                phoneRef.current?.setInputError(t('phone_already_exists'));
              }
            }
          },
        },
      );
    }
  }

  function onPhoneBlur(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    if (value) {
      const phoneWithPlus = value.startsWith('+') ? value : `+${value}`;
      setPersonalData((prev) => ({ ...prev, phone: phoneWithPlus }));
    }
  }

  function getPageData() {
    if (isLoading) {
      return (
        <div className={styles.blankWrapper}>
          <SpinnerOverlay />
        </div>
      );
    }

    if (user) {
      const isCourier = user.profile.name === 'Courier';
      const courierLogin = user.courier_settings?.login || '-';
      const courierPassword = user.courier_settings?.password || '-';
      const isCodeExist = user.courier_settings?.pin_codes[0];
      const courierCode = user.courier_settings?.pin_codes[0] || '-';

      const isUserStatusExist = Number.isInteger(user.status);
      const isChangeStatusDisabled = user.status === UserStatus.DEACTIVATED;
      const profile = user.profile.prototype
        ? t(`users.profile.${user.profile.prototype}`)
        : '-';
      const company = user.company.name || '-';
      const contract = user.contract.number || '-';
      const PersonalCode = user.personal_code || '-';
      const city = user?.addresses?.[0]?.city;
      const street = user?.addresses?.[0]?.street;
      const house = user?.addresses?.[0]?.house;
      const zipCode = user?.addresses?.[0]?.zip_code;
      const fullAddress =
        city && street && house && zipCode
          ? `${city}, ${street}, ${house}, ${zipCode}`
          : '-';

      return userCardFlow === 'default' ? (
        <div className={styles.main}>
          <h1 className={styles.title}>{t('users.user_profile')}</h1>
          <div className={styles.content}>
            <StatusContainer userStatus={user.status} />
            {isUserStatusExist && (
              <Button
                disabled={isChangeStatusDisabled}
                label={t('users.edit')}
                variant="primary"
                onClick={toChangeStatusFlow}
              />
            )}
            {isCourier && (
              <>
                <h2 className={styles.subtitle}>
                  {t('users.access_courier_app')}
                </h2>
                <Input
                  label={t('users.login')}
                  disabled
                  value={courierLogin}
                  extraActions={['copy']}
                />
                <Input
                  label={t('users.password')}
                  disabled
                  value={courierPassword}
                  extraActions={['copy']}
                />
                <h2 className={styles.subtitle}>
                  {t('users.access_parcel_locker')}
                </h2>
                <Input
                  label={t('users.code_in_numbers')}
                  disabled
                  value={courierCode}
                  extraActions={['copy']}
                />
                {isCodeExist && <QRContainer code={courierCode} />}
                {hasAccess && (
                  <Button label={t('users.update_code')} variant="primary" />
                )}
              </>
            )}
            <h2 className={styles.subtitle}>{t('users.accesses')}</h2>
            <Input label={t('users.profile')} disabled value={profile} />
            <Input label={t('users.company')} disabled value={company} />
            <Input label={t('users.contract')} disabled value={contract} />
            <Input
              label={t('users.ID')}
              disabled
              value={PersonalCode}
              extraActions={['copy']}
            />
            <h2 className={styles.subtitle}>{t('users.personal_data')}</h2>
            <Input label={t('users.address')} disabled value={fullAddress} />
            <Input
              ref={emailRef}
              label={t('users.email')}
              name={InputName.EMAIL}
              variant="editing"
              extraActions={['copy']}
              value={personalData.email}
              onChange={onInputChange}
            />
            <Input
              ref={phoneRef}
              label={t('users.phone')}
              name={InputName.PHONE}
              variant="editing"
              extraActions={['copy']}
              value={personalData.phone}
              onChange={onInputChange}
              onBlur={onPhoneBlur}
            />
            <Input
              label={t('users.first_name')}
              name={InputName.FIRST_NAME}
              variant="editing"
              value={personalData.firstName}
              onChange={onInputChange}
            />
            <Input
              label={t('users.last_name')}
              name={InputName.LAST_NAME}
              variant="editing"
              value={personalData.lastName}
              onChange={onInputChange}
            />
            <Button
              isLoading={isPending}
              label={t('users.save_changes')}
              variant="primary"
              disabled={!isActiveSaveChanges}
              onClick={onSaveChanges}
            />
          </div>
        </div>
      ) : (
        <ChangeStatus
          user={user}
          toDefaultFlow={toDefaultFlow}
          refetchUsers={refetchUsers}
          refetchCurrentUser={refetch}
        />
      );
    }

    return (
      <div className={styles.blankWrapper}>
        <p>{t('users.user_not_found')}</p>
      </div>
    );
  }

  return (
    <SideTab ref={sideTabRef} onClose={onSideTabClose}>
      {getPageData()}
    </SideTab>
  );
}
