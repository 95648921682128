import { useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { Cross, Filter, Reset } from 'src/assets/icons';
import { Input } from 'src/components/Input';

import { FiltersMenu } from './components/FiltersMenu';
import styles from './TableHeader.module.scss';
import { TableHeaderProps } from './types';

export default function TableHeader({
  searching,
  selectedFilters,
  extraComponent,
  removeFilter,
  ...props
}: TableHeaderProps) {
  const [isOpenFiltersMenu, setIsOpenFiltersMenu] = useState(false);

  const { t } = useTranslate();

  function openFiltersMenu() {
    setIsOpenFiltersMenu(true);
  }

  function closeFiltersMenu() {
    setIsOpenFiltersMenu(false);
  }

  const isFiltersExist = !!(selectedFilters && selectedFilters?.length);
  const activeFilterButton = isOpenFiltersMenu && styles.activeFilterButton;
  const isActiveResetButton = selectedFilters && selectedFilters?.length >= 2;

  return (
    <>
      <div>
        <div className={styles.topContainer}>
          <div className={styles.topContainerPart}>
            <div className={styles.inputWrapper}>
              <Input
                variant="default"
                placeholder={t('filters.search')}
                value={searching?.searchValue}
                onChange={(event) =>
                  searching?.onSearchChange(event.target.value)
                }
              />
            </div>
            <button
              type="button"
              tabIndex={0}
              aria-label="open filters menu"
              className={cn(styles.filterButton, activeFilterButton)}
              onClick={openFiltersMenu}
            >
              <Filter />
            </button>
          </div>
          {extraComponent}
        </div>
        {isFiltersExist && (
          <div className={styles.activeFiltersContainer}>
            {selectedFilters?.map((filter) => (
              <div key={filter.filterIndex} className={styles.blob}>
                <span>{filter.value}</span>
                <button
                  type="button"
                  tabIndex={-1}
                  aria-label="remove filter"
                  className={styles.blobButton}
                  onClick={() => removeFilter?.(filter.filterIndex)}
                >
                  <Cross />
                </button>
              </div>
            ))}
            {isActiveResetButton && (
              <button
                type="button"
                tabIndex={-2}
                aria-label="remove all filters"
                className={cn(styles.blob, styles.extraBlob)}
                onClick={() => removeFilter?.('all')}
              >
                <span>{t('filters.resetAll')}</span>
                <Reset />
              </button>
            )}
          </div>
        )}
      </div>
      <FiltersMenu
        isOpenFiltersMenu={isOpenFiltersMenu}
        closeFiltersMenu={closeFiltersMenu}
        {...props}
      />
    </>
  );
}
