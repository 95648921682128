import { useEffect, useState } from 'react';
import { Warning } from '@omnic/lk-core';
import { useTranslate } from '@tolgee/react';
import { useUpdateUser } from 'src/api/updateUser';
import { useUserContext } from 'src/context/userContext';

import Button from '../Button';
import { Input } from '../Input';
import styles from './RequiredAddress.module.scss';

export const RequiredAddress = () => {
  const [isShown, setIsShown] = useState(false);

  const { user } = useUserContext();

  const { isPending, mutate } = useUpdateUser();
  const { t } = useTranslate();

  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [postcode, setPostcode] = useState('');

  const country = user?.organization?.country_code;
  const userCountry = country ? t(`country_code.${country}`) : '-';
  const validCity = city.trim();
  const validStreet = street.trim();
  const validStreetNumber = streetNumber.trim();
  const validPostcode = postcode.trim();
  const isActiveButton =
    validCity && validStreet && validStreetNumber && validPostcode;

  function onConfirm() {
    mutate(
      {
        addresses: [
          {
            city: validCity,
            street: validStreet,
            house: validStreetNumber,
            zip_code: validPostcode,
            type: 10,
          },
        ],
      },
      {
        onSuccess: () => setIsShown(false),
      },
    );
  }

  useEffect(() => {
    if (!user) return;

    if (user.pa_warnings?.includes(Warning.NEED_ADDRESS_VERIFICATION)) {
      setIsShown(true);
    }
  }, [user]);

  return (
    <div className={cn(styles.main, !isShown && styles.hide)}>
      <div className={styles.sidebar}>
        <h2 className={styles.title}>{t('user.enter_your_address')}</h2>
        <form className={styles.form}>
          <Input disabled label={t('user.country')} value={userCountry} />
          <Input
            variant="editing"
            placeholder="-"
            label={t('user.street')}
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
          <Input
            variant="editing"
            placeholder="-"
            label={t('user.street_number')}
            value={streetNumber}
            onChange={(e) => setStreetNumber(e.target.value)}
          />
          <Input
            variant="editing"
            placeholder="-"
            label={t('user.postcode')}
            value={postcode}
            onChange={(e) => setPostcode(e.target.value)}
          />
          <Input
            variant="editing"
            placeholder="-"
            label={t('user.city')}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </form>

        <Button
          isLoading={isPending}
          disabled={!isActiveButton}
          label={t('user.confirm')}
          variant="primary"
          onClick={onConfirm}
        />
        <p className={styles.attention}>{t('user.address.attention')}</p>
      </div>
    </div>
  );
};
